import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import FrontPrimaryButton from "../Buttons/FrontPrimaryButton";
import { useTranslation } from 'react-i18next';
import FrontSearcherFilters from "./FrontSearcherFilters";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, router, useForm } from "@inertiajs/react";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import DOMPurify from "dompurify";
import FrontMarkerMap from "../FrontMarkerMap";
import throttle from "@/Helpers/throttle";

const FrontSearcherWithMap = ({ smallHeading, heading, regions, cities, warehouses, allWarehouses, warehousesCount }) => {
    let inputClasses = "bg-white placeholder:text-gray-text text-standard-black font-medium italic text-14 md:pl-c15 pl-2 md:pr-5 pr-2 md:py-c17 py-c11 leading-c17 rounded-r-12 focus:outline-none focus:ring-0 border-transparent border-2 border-l-0 focus:border-yellow transition-all ease-in-out duration-300 max-w-full md:h-14 h-11";
    
    const { data, setData } = useForm({
        provinces: [],
        cities: [],
        keyword: ''
    });

    const { t, i18n } = useTranslation();
    const [isFiltersVisible, setIsFiltersVisible] = useState(false);
    const [stateRegions, setStateRegions] = useState(regions);

    const submit = (e) => {
        e.preventDefault();
        
        if( (data.provinces.length >= 1 && data.cities.length >= 1 ) || data.provinces.length > 1 || data.cities.length > 1 ){
            // front.warehouses.all.manyLocations
            if(i18n.resolvedLanguage === 'pl') {
                router.visit( route('front.warehouses.manyLocations' ), {
                    data: {
                        provinces: '[' + data.provinces.join(',') + ']',
                        cities: '[' + data.cities.join(',') + ']',
                    }
                })
            }else {
                router.visit( route('front.warehouses.manyLocations.en' ), {
                    data: {
                        provinces: '[' + data.provinces.join(',') + ']',
                        cities: '[' + data.cities.join(',') + ']',
                    }
                })
            }
        }else if( data.provinces.length === 0 && data.cities.length === 0 ){
            // results by keywords, search in warehouse name and landlord name
            if(i18n.resolvedLanguage === 'pl') {
                router.visit( route('front.warehouses', { searchRegion: data.keyword } ))
            }else {
                router.visit( route('front.warehouses.en', { searchRegion: data.keyword } ))
            }
        }else {
            // single results page
            let regionToFind;

            if(data.provinces[0]){
                regionToFind = data.provinces[0]
            }

            if(data.cities[0]){
                regionToFind = data.cities[0]
            }
            
            if(i18n.resolvedLanguage === 'pl') {
                router.visit( route('front.warehouses', { searchRegion: regionToFind } ))
            }else {
                router.visit( route('front.warehouses.en', { searchRegion: regionToFind } ))
            }
        }
    }

    function outsideClick(ref) {
        useEffect(() => {
          /**
           * Hide if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsFiltersVisible(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    outsideClick(wrapperRef);

    const handleSearch = (e) => {
        const keyword = e.currentTarget.value;
        setData('keyword', keyword);
        
        if(i18n.resolvedLanguage == 'pl'){
            router.visit(route('front.home', {keyword: keyword}),
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                    only: ['regions', 'cities', 'warehouses'],
                    onSuccess: (data) => {
                        setStateRegions(data.props.regions)
                    }
                }
            );
        } else {
            router.visit(
                route('front.home.en', {keyword: keyword}),
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                    only: ['regions', 'cities', 'warehouses'],
                    onSuccess: (data) => {
                        setStateRegions(data.props.regions)
                    }
                }
            );
        }
    }

    const throttledHandleSearch = useMemo(() => throttle(handleSearch, 50), []);

    return (
        <div>
            <div className="sm:hidden block bg-new-blue py-3">
                <Container>
                    <Row>
                        <Column>
                            {/* <FrontText
                                textSize="14/17"
                                className="font-light text-white mb-1 uppercase"
                            >
                                {smallHeading}
                            </FrontText> */}
                            <FrontText
                                textSize="16/20"
                                className="font-light text-white uppercase"
                            >
                                {heading}
                            </FrontText>
                        </Column>
                    </Row>
                </Container>
            </div>

            <div className="map relative md:block hidden">
                <FrontMarkerMap allWarehouses={allWarehouses} />
            </div>
            <Container className="md:-mt-40 md:pt-0 pt-5 relative z-c2 pointer-events-none">
                <Row>
                    <Column xl={4} lg={5} md={6} sm={6} className='pointer-events-auto'>
                        <div className="bg-dark-blue-standard rounded-t-20 xl:pl-16 lg:pl-10 px-5 xl:pr-10 lg:pr-8 pr-6 xl:pt-4 pt-5">
                            <div className="sm:block hidden">
                                {/* <FrontText
                                    textSize="14/17"
                                    className="font-light text-white mb-1.5 uppercase"
                                >
                                    {smallHeading}
                                </FrontText> */}
                                <FrontText
                                    textSize="16/20"
                                    className="font-light mb-3 text-white uppercase"
                                >
                                    {heading}
                                </FrontText>
                            </div>
                            <div className="flex gap-8 w-max border-b border-white pb-3 sm:mx-0 mx-auto">
                                <img
                                    src="/storage/icons/warehouse-icon.svg"
                                    width={30}
                                    height={30}
                                    alt={t('alts.homewhm')}
                                />
                                <div>
                                    <FrontText
                                        textSize="25/30"
                                        className="text-white font-bold"
                                    >
                                        <span>{warehousesCount}</span>
                                    </FrontText>
                                    <FrontText
                                        textSize="16/20"
                                        className="text-white"
                                    >
                                        <span>{t('search_form.available_warehouses')}</span>
                                    </FrontText>
                                </div>
                            </div>
                        </div>
                    </Column>
                    <Column className='pointer-events-auto'>
                        <form onSubmit={submit} className="bg-dark-blue-standard rounded-b-20 sm:rounded-tr-20 xl:px-16 lg:px-10 px-5 xl:pb-12 lg:pb-10 pb-5 pt-5 flex gap-5 items-end lg:flex-nowrap flex-wrap">
                            <div className="w-full" ref={wrapperRef}>
                                <label htmlFor="city">
                                    <FrontText
                                        textSize="14/17"
                                        className="text-white font-bold mb-2"
                                    >
                                        {t('search_form.location')}
                                    </FrontText>
                                </label>

                                <div className="relative h-full md:pl-14 pl-11">
                                    <div className="absolute left-0 h-full md:w-14 w-11 bg-yellow rounded-l-12 flex items-center justify-center">
                                        <img
                                            src="/storage/icons/localisation-icon.svg"
                                            width={18}
                                            height={26}
                                            alt={t('alts.pinhome')}
                                            className="md:w-auto md:h-auto w-[13px] h-[19px]"
                                        />
                                    </div>
                                    <input
                                        className={`${inputClasses} ${isFiltersVisible && 'border-yellow'} w-full`}
                                        id="keyword"
                                        name="keyword"
                                        autoComplete='off'
                                        type="text"
                                        onFocus={() => setIsFiltersVisible(true)}
                                        onChange={throttledHandleSearch}
                                        placeholder={t('search_form.location_placeholder')}
                                    />
                                    <div className="relative">
                                        <FrontSearcherFilters 
                                            regions={stateRegions}
                                            cities={cities}
                                            warehouses={warehouses}
                                            visible={isFiltersVisible}
                                            setSelected={(selectedProvinces, selectedCities) => {
                                                setData({
                                                    provinces: selectedProvinces,
                                                    cities: selectedCities,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="max-w-50">
                                <label htmlFor="area_from">
                                    <FrontText
                                        textSize="14/17"
                                        className="text-white font-bold mb-2"
                                    >
                                        <Trans i18nKey={'search_form.area_from'}/>
                                    </FrontText>
                                </label>

                                <div className="relative h-full pl-14">
                                    <div className="absolute left-0 h-full w-14 bg-yellow rounded-l-12 flex items-center justify-center">
                                        <img
                                            src="/storage/icons/icon-m2-black.svg"
                                            width={34}
                                            height={34}
                                            alt={t('alts.metershome')}
                                        />
                                    </div>
                                    <input
                                        className={`${inputClasses}`}
                                        id="area_from"
                                        name="area_from"
                                        type="number"
                                        placeholder={t('search_form.area_from_placeholder')}
                                    />
                                </div>
                            </div> */}

                            {/* <div className="max-w-50">
                                <label htmlFor="area_to">
                                    <FrontText
                                        textSize="14/17"
                                        className="text-white font-bold mb-2"
                                    >
                                        <Trans i18nKey={'search_form.area_to'}/>
                                    </FrontText>
                                </label>

                                <div className="relative h-full pl-14">
                                    <div className="absolute left-0 h-full w-14 bg-yellow rounded-l-12 flex items-center justify-center">
                                        <img
                                            src="/storage/icons/icon-m2-black.svg"
                                            width={34}
                                            height={34}
                                            alt={t('alts.metershome')}
                                        />
                                    </div>
                                    <input
                                        className={`${inputClasses}`}
                                        id="area_to"
                                        name="area_to"
                                        type="number"
                                        placeholder={t('search_form.area_to_placeholder')}
                                    />
                                </div>
                            </div> */}

                            <FrontPrimaryButton displayAsButton type="submit" className="lg:mx-5 lg:w-auto w-full" title={t('search_form.search')}>
                                {t('search_form.search')}
                            </FrontPrimaryButton>
                            <Link className="lg:w-auto w-full" href={resolveLocaleRoute('front.warehouses.all')} title={t('search_form.search_all')}>
                                <FrontPrimaryButton displayAsButton outline className="lg:w-auto w-full" title={t('search_form.search_all')}>
                                    {t('search_form.search_all')}
                                </FrontPrimaryButton>
                            </Link>
                        </form>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontSearcherWithMap;
