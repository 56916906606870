import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import FrontDotsDecoration from "../Partials/FrontDotsDecoration";
import FrontRoundedButton from "../Buttons/FrontRoundedButton";
import { Link } from "@inertiajs/react";
import { Trans, useTranslation } from "react-i18next";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";

const FrontServices = ({ cards }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="bg-new-blue md:py-c94 py-21 overflow-hidden">
            <Container>
                <Row>
                    <Column className='flex'>
                        <div className="md:bg-white md:rounded-20 md:relative flex flex-wrap gap-y-c85">
                        <FrontDotsDecoration className="md:block hidden absolute -top-[34px] -left-[47px]" />
                        <FrontDotsDecoration className="md:block hidden absolute -bottom-[34px] -right-[47px]" />
                        {cards.map((el, index) => {
                            let image = t(el.image, { returnObjects: true });
                            let button = t(el.button, { returnObjects: true });
                            return (
                                <div className="bg-white rounded-20 md:bg-none md:w-1/3 w-full flex" key={index}>
                                    <div className="lg:px-5 px-3 md:pb-15 pb-10 flex-col flex">
                                        <img
                                            src={`/storage/icons/${image.src}`}
                                            alt={image.alt}
                                            width={90}
                                            height={90}
                                            className="-mt-c45 mx-auto"
                                        />

                                        <div>
                                            <FrontText
                                                className="text-light-blue text-center font-bold mt-c22 font-domine"
                                                textSize={"38/43"}
                                            >
                                                <Trans i18nKey={el.heading} />
                                            </FrontText>

                                            <FrontText
                                                textSize={"13/16"}
                                                className="text-center text-black my-c22 font-bold"
                                            >
                                                <Trans
                                                    i18nKey={el.description}
                                                />
                                            </FrontText>
                                        </div>

                                        <div className="justify-center flex mt-auto">
                                            <Link
                                                href={resolveLocaleRoute(
                                                    button.href,
                                                    null,
                                                    i18n.resolvedLanguage
                                                )}
                                                title={button.title}
                                            >
                                                <FrontRoundedButton className="mx-auto">
                                                    {button.title}
                                                </FrontRoundedButton>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontServices;
