import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import { Link } from "@inertiajs/react";
import { Icon } from "@iconify/react";
import FrontSmallContactButton from "../Buttons/FrontSmallContactButton";
import FrontSectionHeading from "../Partials/FrontSectionHeading";
import { Trans } from "react-i18next";
import WebpImage from "./WebpImage";

const FrontAboutOurCompany = ({
    sectionHeading,
    image,
    content,
    checklist,
}) => {
    return (
        <div>
            <FrontSectionHeading>{sectionHeading}</FrontSectionHeading>
            <Container>
                <Row className="justify-between items-center md:gap-0 gap-y-10">
                    <Column xl={6} lg={6} md={6} className='order-1'>
                        <div className="relative lg:py-3">
                            <div className="lg:pl-13 pl-c30 lg:pr-8 pr-4 lg:py-5 py-6">
                                <WebpImage
                                    className="rounded-10"
                                    sources={[
                                        {
                                            width: 768,
                                            path: `/storage/images/${image.name}`,
                                            mime: "jpg",
                                        },
                                        {
                                            width: 1,
                                            path: `/storage/images/${image.name}-size-530`,
                                            mime: "jpg",
                                        },
                                    ]}
                                    alt={image.alt}
                                    fallback={image.url}
                                    seo={{
                                        width: 530,
                                        height: 322,
                                    }}
                                />
                            </div>
                            <div className="absolute w-2/3 h-full rounded-10 bg-gradient-orange-to-yellow top-0 -z-c1 left-0"></div>
                            <div className="absolute w-full h-full py-3 top-0 -z-c2 left-0">
                                <div className="h-full w-full rounded-10 border-dashed border-3 border-tango-red"></div>
                            </div>
                        </div>
                    </Column>

                    <Column xl={5} lg={5} md={6} className='md:order-2 order-3'>
                        <div>
                            <FrontText
                                textSize="16/20-m-same"
                                className="text-standard-black"
                                spacings
                            >
                                <Trans i18nKey={content} />
                            </FrontText>
                        </div>
                        {/* {content.map((el, index) => (
                            <div key={index}>
                                <div className="flex items-center gap-3">
                                    <FrontText
                                        textSize="96/109"
                                        className="text-dark-blue-standard font-bold font-domine"
                                    >
                                        {index + 1}
                                    </FrontText>

                                    <FrontText
                                        textSize="24/29"
                                        className="text-dark-blue-standard font-bold"
                                    >
                                        <Trans i18nKey={el.title} />
                                    </FrontText>
                                </div>

                                <div>
                                    <FrontText textSize="16/20">
                                        <Trans i18nKey={el.description} />
                                    </FrontText>
                                </div>
                            </div>
                        ))} */}
                    </Column>
                    <Column className='md:order-3 order-2'>
                        <div>
                            <ul className="md:mt-21 grid md:grid-cols-2  xl:gap-x-15 lg:gap-x-10 md:gap-x-4 gap-y-5">
                                {checklist.map((el, index) => (
                                    <li
                                        key={index}
                                        className="flex items-center gap-c15"
                                    >
                                        <Icon
                                            icon="material-symbols:check-circle-outline-rounded"
                                            width={40}
                                            height={40}
                                            className="text-yellow shrink-0"
                                        />
                                        <FrontText
                                            textSize="16/20"
                                            className="font-bold text-standard-black"
                                        >
                                            <Trans i18nKey={el} />
                                        </FrontText>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontAboutOurCompany;
