import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import Column from "@/Components/Grid/Column";
import FrontText from "../FrontText";

const FrontSectionHeading = ({children, marginClass}) => {
    return (
        <div className={`${marginClass ? marginClass : 'md:mb-15 mb-10'}`}>
            <Container>
                <Row className='justify-center'>
                    <Column xl={9} lg={10} md={11}>
                        <FrontText
                            textSize={"38/43-m-24/27"}
                            className="font-bold font-domine text-heading-blue text-center mb-5"
                        >
                            {children}
                        </FrontText>
                        <div className="w-21 mx-auto bg-heading-blue h-[3px]"></div>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontSectionHeading;
