import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import { Link } from "@inertiajs/react";
import { Icon } from "@iconify/react";
import FrontSmallContactButton from "../Buttons/FrontSmallContactButton";
import FrontSectionHeading from "../Partials/FrontSectionHeading";
import WebpImage from "./WebpImage";
import { useTranslation } from "react-i18next";
import { register } from "swiper/element/bundle";
import { useRef, useState, useEffect, useCallback } from "react";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";

register();

const FrontLatestWarehouses = ({ sectionHeading, cards }) => {
    const { t, i18n } = useTranslation();

    /* =================== [Mobile Swiper] =================== */
    const swiperRef = useRef(null);
    const [ShouldInitSwiper, setShouldInitSwiper] = useState(false);

    useEffect(() => {
        if (!swiperRef.current) {
            return;
        }

        const swiperContainer = swiperRef.current;

        const params = {
            slidesPerView: 1,
            navigation: true,
            loop: true,
            spaceBetween: 20,
            injectStyles: [
                `
                    .swiper-button-next,
                    .swiper-button-prev {
                        display: none
                    }
                `,
            ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    }, [ShouldInitSwiper]);

    /* =================== [Resize handler] =================== */
    useEffect(() => {
        const handler = () => {
            if (window.innerWidth >= 768) {
                setShouldInitSwiper(false);
            } else {
                setShouldInitSwiper(true);
            }
        };

        handler();

        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, [ShouldInitSwiper]);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, []);

    /* =================== [Mobile Swiper] =================== */

    const template = cards.map((elem, index) => (
        <Column xl={4} lg={4} md={4} key={index}>
            <div className="bg-white shadow-28px md:mx-0 mx-10 md:mt-0 mt-10 md:mb-0 mb-20 flex flex-col h-full">
                <div>
                    <Link href={resolveLocaleRoute('front.warehouses.single', { province: elem.get_region.slug, slug: elem.slug }, i18n.resolvedLanguage)} title={t('alts.showDetails') + ' - ' + elem.name}>
                    <WebpImage
                        className="w-full object-cover object-center md:h-[253px] sm:h-48 h-36"
                        sources={[
                            {
                                width: 768,
                                path: `/storage/warehouses/${elem.gallery_main.substring(0, elem.gallery_main.lastIndexOf('.'))}-300`,
                                mime: elem.gallery_main.split('.').pop(),
                            },
                            {
                                width: 1,
                                path: `/storage/warehouses/${elem.gallery_main.substring(0, elem.gallery_main.lastIndexOf('.'))}-700`,
                                mime: elem.gallery_main.split('.').pop(),
                            },
                        ]}
                        alt={elem.name}
                        fallback={`/storage/warehouses/${elem.gallery_main}`}
                        seo={{ width: 420, height: 253 }}
                    />
                    </Link>
                </div>

                <div className="lg:px-c22 px-3 lg:pt-7 pt-3 lg:pb-6 pb-5 flex flex-col h-full">
                    <FrontText
                        textSize={"28/34"}
                        className="font-semibold text-30548A"
                        customLinkStyles
                    >
                        <Link href={resolveLocaleRoute('front.warehouses.single', { province: elem.get_region.slug, slug: elem.slug }, i18n.resolvedLanguage)} title={t('alts.showDetails') + ' - ' + elem.name} className="hover:text-light-yellow transition-all ease-in-out duration-300">
                            <h3>{elem.name}</h3>
                        </Link>
                    </FrontText>
                    <FrontText
                        textSize={"14/17"}
                        className="font-semibold text-standard-black md:mb-c18 mb-3"
                        customLinkStyles
                    >
                        <Link href={resolveLocaleRoute('front.warehouses', {searchRegion: elem.city_slug ? elem.city_slug : i18n.resolvedLanguage == 'pl' ? elem.get_region.slug : elem.get_region.slug_en}, i18n.resolvedLanguage)} className="hover:text-yellow transition-all ease-in-out duration-200">
                            <p>{elem.city ? elem.city : elem.get_region.name}</p>
                        </Link>
                    </FrontText>

                    <hr className="mt-auto border-standard-black md:mb-c22 mb-3" />

                    <div className="flex sm:justify-between justify-start items-start sm:flex-row flex-col sm:gap-y-0 gap-c10">
                        <div className="flex items-center sm:flex-col gap-c10">
                            <img
                                src="/storage/icons/icon-localisation.svg"
                                alt={t('alts.location')}
                                width={18}
                                height={26}
                            />

                            <FrontText
                                textSize={"16/20"}
                                className="font-semibold text-standard-black text-center"
                                customLinkStyles
                            >
                                <Link href={resolveLocaleRoute('front.warehouses', {searchRegion: elem.city_slug ? elem.city_slug : i18n.resolvedLanguage == 'pl' ? elem.get_region.slug : elem.get_region.slug_en}, i18n.resolvedLanguage)} className="hover:text-yellow transition-all ease-in-out duration-200">
                                    <p>{elem.city ? elem.city : elem.get_region.name}</p>
                                </Link>
                            </FrontText>
                        </div>

                        <div className="flex items-center sm:flex-col gap-c10">
                            <img
                                src="/storage/icons/icon-m2.svg"
                                alt={t('alts.squareFootage')}
                                width={30}
                                height={31}
                            />

                            <FrontText
                                textSize={"16/20"}
                                className="font-semibold text-standard-black text-center"
                            >
                                <p className="whitespace-nowrap">
                                    {elem.total_area_building} m<sup>2</sup>
                                </p>
                            </FrontText>
                        </div>

                        <div className="flex items-center sm:flex-col gap-c10">
                            <img
                                src="/storage/icons/warehouse.svg"
                                alt={t('alts.warehouse')}
                                width={30}
                                height={28}
                            />

                            <FrontText
                                textSize={"16/20"}
                                className="font-semibold text-standard-black text-center"
                            >
                                <p>{elem.landlord}</p>
                            </FrontText>
                        </div>
                    </div>

                    <hr className="md:border-standard-black border-transparent md:mt-c18 md:mb-6 mt-5" />

                    <Link
                        href={resolveLocaleRoute('front.warehouses.single', {
                            province: elem.get_region.slug,
                            slug: elem.slug
                        }, i18n.resolvedLanguage)+'#advisors'}
                        className="flex justify-center"
                        title={t("warehouses.contactAdvisor")}
                        rel="nofollow"
                    >
                        <FrontSmallContactButton
                            type=""
                            className="md:w-auto w-full justify-center"
                        >
                            <span>{t("warehouses.contactAdvisor")}</span>
                        </FrontSmallContactButton>
                    </Link>
                </div>

                <Link
                    href={resolveLocaleRoute('front.warehouses.single', { province: elem.get_region.slug, slug: elem.slug }, i18n.resolvedLanguage)}
                    title={t("alts.showDetails")}
                    className="bg-light-blue text-white hover:bg-blue-classic block transition-all ease-in-out duration-300"
                    rel="nofollow"
                >
                    <div className="gap-[10px] flex items-center px-5 lg:py-c22 py-2 justify-center">
                        <FrontText
                            textSize={"16/20-m-14/17"}
                            className="font-semibold"
                        >
                            <span>{t("alts.showDetails")}</span>
                        </FrontText>

                        <Icon
                            icon={"material-symbols:arrow-right-alt"}
                            width={24}
                            height={24}
                        />
                    </div>
                </Link>
            </div>
        </Column>
    ));

    return (
        <div className="lg:pt-20 md:pt-15 pt-10 lg:pb-20 md:pb-15 pb-0">
            {sectionHeading && (
                <FrontSectionHeading marginClass="md:mb-15">
                    {sectionHeading}
                </FrontSectionHeading>
            )}

            {ShouldInitSwiper && (
                <div className="relative">
                    <swiper-container ref={swiperRef} init="false">
                        {template.map((elem, index) => (
                            <swiper-slide key={index}>{elem}</swiper-slide>
                        ))}
                    </swiper-container>

                    <button
                        id="prev-slide"
                        onClick={handlePrev}
                        className="absolute left-5 top-1/2 transform -translate-y-1/2 z-c1 p-[11px] bg-30548A text-white hover:bg-[rgba(48,84,138,0.87)] transition-all ease-in-out duration-300"
                        title={t("warehouses.prevSlide")}
                    >
                        <div className="transition-all ease-in-out duration-200">
                            <Icon
                                icon="mingcute:left-fill"
                                width={18}
                                height={18}
                            />
                        </div>
                    </button>
                    <button
                        id="next"
                        onClick={handleNext}
                        className="absolute right-5 top-1/2 transform -translate-y-1/2 z-c1 p-[11px] bg-30548A text-white hover:bg-[rgba(48,84,138,0.87)] transition-all ease-in-out duration-300"
                        title={t("warehouses.nextSlide")}
                    >
                        <div className="transition-all ease-in-out duration-200">
                            <Icon
                                icon="mingcute:right-fill"
                                width={18}
                                height={18}
                            />
                        </div>
                    </button>
                </div>
            )}

            <Container>
                <Row>{!ShouldInitSwiper && template}</Row>
            </Container>
        </div>
    );
};

export default FrontLatestWarehouses;
