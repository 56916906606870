import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import { Link } from "@inertiajs/react";
import { Icon } from "@iconify/react";
import NEWEST_POSTS_FRAGMENT from "@/Query/Fragments/NEWEST_POSTS_FRAGMENT";
import LargeSkeleton from "@/Components/Preloaders/LargeSkeleton";
import Skeleton from "@/Components/Preloaders/Skeleton";
import GraphImage from "../GraphImage/GraphImage";
import { useTranslation } from "react-i18next";
import FrontRoundedButton from "../Buttons/FrontRoundedButton";
import { useRef, useState, useEffect } from "react";

// import * as ApolloPkg from '@apollo/client';
// import { gql } from '@apollo/client/core/core.cjs';
// import { useQuery } from "@apollo/client/react/hooks/hooks.cjs";
// import { useQuery, gql } from "@apollo/client/apollo-client.min.cjs";
// import { useQuery, gql } from "@apollo/client";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client/react/hooks/hooks.cjs";

import { register } from "swiper/element/bundle";

register();

const FrontLatestArticles = ({
    heading,
    description,
    button,
    tax = ['raporty', 'blog', 'aktualnosci'],
    taxEn = ['reports', 'blog-en', 'news'],
}) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage.toUpperCase();
    // const { gql, useQuery } = ApolloPkg

    /* =================== [Raporty] =================== */
    const LATEST_POSTS_FROM_TAX = gql`
        query GetPosts($categoryName: String!, $lang: LanguageCodeFilterEnum!) {
            posts(
                first: 1
                where: { categoryName: $categoryName, status: PUBLISH, language: $lang }
            ) {
                edges {
                    node {
                        ${NEWEST_POSTS_FRAGMENT}
                    }
                }
            }
        }
    `;

    const { loading: loading_reports, error: error_reports, data: data_reports } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage === 'PL' ? tax[0] : taxEn[0], lang: currentLanguage
        },
        ssr: true,
    });

    const { loading: loading_blog, error: error_blog, data: data_blog } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage === 'PL' ? tax[1] : taxEn[1], lang: currentLanguage
        },
        ssr: true,
    });

    const { loading: loading_news, error: error_news, data: data_news } = useQuery(LATEST_POSTS_FROM_TAX, {
        variables: {
            categoryName: currentLanguage === 'PL' ? tax[2] : taxEn[2], lang: currentLanguage
        },
        ssr: true,
    });


    /* =================== [Swiper Mobile] =================== */
    const swiperRef = useRef(null);
    const [ShouldInitSwiper, setShouldInitSwiper] = useState(false);

    useEffect(() => {
        if (!swiperRef.current) {
            return;
        }

        const swiperContainer = swiperRef.current;

        const params = {
            slidesPerView: 1.3,

            injectStyles: [
                `
            ::slotted(swiper-slide) {
                display: flex;
                justify-content: center;
                align-content: center;
                height: auto;
            }
        `,
            ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    }, [ShouldInitSwiper, loading_reports, loading_blog, loading_news]);

    /* =================== [Resize handler] =================== */
    useEffect(() => {
        const handler = () => {
            if (window.innerWidth >= 768) {
                setShouldInitSwiper(false);
            } else {
                setShouldInitSwiper(true);
            }
        };

        handler();

        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, [ShouldInitSwiper]);
    /* =================== [Swiper Mobile] =================== */


    const [mixedPosts, setMixedPosts] = useState([]);
    useEffect(() => {
        if(!loading_blog && !loading_news && !loading_reports){
            const mixedData = [
                { data: data_reports, source: currentLanguage === 'PL' ? tax[0] : taxEn[0] },
                { data: data_blog, source: currentLanguage === 'PL' ? tax[1] : taxEn[1] },
                { data: data_news, source: currentLanguage === 'PL' ? tax[2] : taxEn[2] }
            ];
        
            const filteredMixedData = mixedData.filter(item => item.data !== undefined);
            setMixedPosts(filteredMixedData);
        }
    }, [data_blog,data_news, data_reports])
    
    /* =================== [Template] =================== */
    const template =
        mixedPosts.map((elem) => {
            return elem.data.posts.edges.map((el, index) => {
                const { id, slug, title, featuredImage, date, excerpt } = el.node;
                const { sizes } = featuredImage.node.mediaDetails;
                const { mediaItemUrl } = featuredImage.node;
                
                return (
                    <Column
                        xl={4}
                        lg={4}
                        md={4}
                        key={el.title + "-" + index}
                        className="md:py-0 py-12 md:first:mt-20 md:first:mb-0 md:my-10 md:last:mt-0 md:last:mb-20"
                    >
                        <Link
                            key={id + index}
                            preserveScroll={false}
                            href={route("front.news.single", {
                                taxonomy: elem.source,
                                slug: slug,
                            })}
                            title={title}
                            className="block hover:[&>div]:bg-light-blue [&:hover_.text-wrapper]:text-white [&:hover_.icon-wrapper]:text-light-yellow h-full"
                        >
                            <div className="bg-white rounded-10 drop-shadow-box pb-5 transition-all ease-in-out duration-300 h-full flex flex-col">
                                <div>
                                    <GraphImage
                                        lazy="lazy"
                                        sizes={sizes}
                                        original={mediaItemUrl}
                                        className="rounded-t-10 w-full h-[150px]"
                                        alt={title}
                                    />
                                </div>

                                <div className="mx-5 flex flex-col h-full">
                                    <FrontText
                                        textSize="14/16"
                                        className="text-wrapper my-5 text-black font-bold duration-300 ease-in-out transition-all"
                                    >
                                        <p>{title}</p>
                                    </FrontText>
                                    <div className="flex justify-end mt-auto">
                                        <Icon
                                            icon="material-symbols:menu-book-rounded"
                                            className="text-30548A icon-wrapper transition-all ease-in-out duration-300"
                                            width={27}
                                            height={24}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Column>
                );
            })
        });
    return (
        <div>
            <Container>
                <Row className="justify-between gap-y-10">
                    <Column xl={4} lg={4} className="mt-20">
                        <FrontText
                            textSize="38/43"
                            className="text-heading-blue font-domine font-bold"
                        >
                            {heading}
                        </FrontText>

                        <FrontText
                            textSize="16/20-m-same"
                            className="text-standard-black font-medium my-c30"
                        >
                            {description}
                        </FrontText>

                        <Link
                            href={button.href}
                            title={button.title}
                            preserveScroll={false}
                        >
                            <FrontRoundedButton>
                                {button.title}
                            </FrontRoundedButton>
                        </Link>
                    </Column>
                    <Column xl={8} lg={8} className='md:block hidden'>
                        <Row>
                            {error_reports && error_blog && error_news && (
                                <Column>
                                    <Skeleton />
                                </Column>
                            )}
                            {!ShouldInitSwiper && loading_reports && loading_blog && loading_news ? (
                                <>
                                    <Column
                                        xl={4}
                                        className="first:mt-20 mt-10 last:mt-0"
                                    >
                                        <Skeleton />
                                    </Column>

                                    <Column
                                        xl={4}
                                        className="first:mt-20 mt-10 last:mt-0"
                                    >
                                        <Skeleton />
                                    </Column>

                                    <Column
                                        xl={4}
                                        className="first:mt-20 mt-10 last:mt-0"
                                    >
                                        <Skeleton />
                                    </Column>
                                </>
                            ) : (
                                !ShouldInitSwiper && template
                            )}
                        </Row>
                    </Column>
                </Row>
            </Container>
            {loading_reports && loading_blog && loading_news 
                ?
                    (
                        <Column>
                            <Skeleton />
                        </Column>
                    )
                : 
                ShouldInitSwiper && (
                    <div>
                        <swiper-container ref={swiperRef} init="false">
                            {template.map((elem, index) => (
                                <swiper-slide key={index}>{elem}</swiper-slide>
                            ))}
                        </swiper-container>
                    </div>
                )
            }
        </div>
    );
};

export default FrontLatestArticles;
