import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";
import FrontSectionHeading from "../../Partials/FrontSectionHeading";
import WebpImage from "../WebpImage";

register();

const FrontOurPartners = ({ sectionHeading }) => {
    const swiperRef = useRef(null);

    useEffect(() => {
        const swiperContainer = swiperRef.current;
        const params = {
            slidesPerView: 1.9,
            navigation: true,
            loop: true,
            spaceBetween: 20,
            autoplay: {
                delay: 2500,
            },
            breakpoints: {
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
                640: {
                    slidesPerView: 3,
                }
            },

            injectStyles: [
                `
            .swiper-button-next,
            .swiper-button-prev {
              background-position: center;
              background-size: 56px;
              background-repeat: no-repeat;
              width: 56px;
              height: 56px;
            }

            .swiper-button-next svg, 
            .swiper-button-prev svg {
                display: none;
            }

            .swiper-button-next {
                rigth: 0;
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper {
                position: static;
            }

            :host {
                position: static;
            }
  
            .swiper-button-prev {
                background-image: url("/storage/icons/ic_round-navigate-prev.svg");
            }
  
            .swiper-button-next {
              background-image: url("/storage/icons/ic_round-navigate-next.svg");
            }
  
            .swiper-button-next::after,
            .swiper-button-prev::after {
              content: "";
            }

            @media (max-width:767px) {
                .swiper-button-next,
                .swiper-button-prev {
                    display:none;
                }
            }

            ::slotted(swiper-slide) {
                display: flex;
                justify-content: center;
                align-content: center;
                height: auto;
            }
        `,
            ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    }, []);

    return (
        <div>
            {sectionHeading && (
                <FrontSectionHeading>{sectionHeading}</FrontSectionHeading>
            )}
            <div className="bg-new-blue xl:py-30 lg:py-24 md:py-20 py-10">
                <Container className="">
                    <Row>
                        <Column className="relative">
                            <Row className="justify-center">
                                <Column xl={10} lg={10} md={10} className='md:px-0'>
                                    <div className="bg-white rounded-20 border border-30548A lg:py-14 md:py-10 sm:py-7 py-4 xl:px-15 lg:px-10 px-8">
                                    <swiper-container
                                        ref={swiperRef}
                                        init="false"
                                    >
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/7r`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'7R'}
                                                    fallback={`/storage/partners/7r.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/bik`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'BIK'}
                                                    fallback={`/storage/partners/bik.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/cromwell`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Cromwell'}
                                                    fallback={`/storage/partners/cromwell.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/DLInvest`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'DLInvest Group'}
                                                    fallback={`/storage/partners/DLInvest.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/exeter`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Exeter Property Group'}
                                                    fallback={`/storage/partners/exeter.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/GLP`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'GLP'}
                                                    fallback={`/storage/partners/GLP.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/hillwood`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Hillwood'}
                                                    fallback={`/storage/partners/hillwood.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/idealidea`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Idealidea'}
                                                    fallback={`/storage/partners/idealidea.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/logicor`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Logicor'}
                                                    fallback={`/storage/partners/logicor.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/mlp`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'MLP Group'}
                                                    fallback={`/storage/partners/mlp.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/p3`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'P3 Logistic Parks'}
                                                    fallback={`/storage/partners/p3.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/panattoni`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Panattoni Europe'}
                                                    fallback={`/storage/partners/panattoni.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/prologis`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Prologis'}
                                                    fallback={`/storage/partners/prologis.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/sacillis-im`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Savills Investment Management'}
                                                    fallback={`/storage/partners/sacillis-im.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/segro`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'SEGRO'}
                                                    fallback={`/storage/partners/segro.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/waimea`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'Waimea'}
                                                    fallback={`/storage/partners/waimea.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div className="flex items-center">
                                                <WebpImage
                                                    className="w-auto h-24 object-contain"
                                                    lazy
                                                    sources={[
                                                        {
                                                            width: 1,
                                                            path: `/storage/partners/whitestar`,
                                                            mime: 'png'
                                                        },
                                                    ]}
                                                    alt={'White Star'}
                                                    fallback={`/storage/partners/whitestar.png`}
                                                    seo={{
                                                        width: 176,
                                                        height: 65
                                                    }}
                                                />
                                            </div>
                                        </swiper-slide>
                                    </swiper-container>
                                    </div>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default FrontOurPartners;
