import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import FrontText from "../FrontText";
import { Link } from "@inertiajs/react";
import { Icon } from "@iconify/react";
import FrontSectionHeading from "../Partials/FrontSectionHeading";
import { Trans, useTranslation } from "react-i18next";
import WebpImage from "./WebpImage";

const FrontWhatWeOffers = ({
    sectionHeading,
    image,
    content,
    listItems,
    button
}) => {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <FrontSectionHeading>{sectionHeading}</FrontSectionHeading>
            <Container>
                <Row className="justify-between flex-wrap-reverse gap-y-10">
                    <Column xl={6} lg={6}>
                        <div className="lg:pl-7 pl-5 xl:pr-18 lg:pr-16 md:pr-10 relative before:absolute before:h-full before:w-2 before:bg-light-blue before:left-0 before:top-0">
                            <FrontText textSize='16/20' className="text-standard-black" spacings strongColor>
                                {content}
                            </FrontText>
                        </div>
                        <Link href={button.href} title={button.title} className="bg-yellow rounded-lg px-8 py-4 inline-block duration-200 ease-in-out transition-all text-black hover:bg-dark-blue-standard hover:text-white md:mt-c30 mt-10 md:ml-7 md:w-auto w-full text-center">
                            <FrontText textSize='16/20' className="font-semibold uppercase">
                                {button.title}
                            </FrontText>
                        </Link>
                    </Column>
                    <Column xl={6} lg={6}>
                        <div className="mb-8">
                            <WebpImage
                                className='drop-shadow-box w-full'
                                sources={[
                                    {
                                        width: 768,
                                        path: `/storage/images/${image.name}-size-1300`,
                                        mime: 'jpg'
                                    },
                                    {
                                        width: 1,
                                        path: `/storage/images/${image.name}-size-767`,
                                        mime: 'jpg'
                                    }
                                ]}
                                alt={image.alt}
                                fallback={image.src}
                                seo={{
                                    width: 610,
                                    height: 407
                                }}
                            />
                        </div>

                        <div className="flex flex-wrap lg:gap-y-c30 gap-y-4">
                            {listItems.map((elem, index) => {
                                let icon = t(elem.icon, { returnObjects: true });

                                return (
                                    <div className="md:w-1/2 w-full flex items-center gap-c10 pr-3" key={index}>
                                        <div className="shrink-0 max-w-[44px] w-full justify-center flex">
                                            {icon.url ? (
                                                <img src={icon.url} alt={icon.alt} width={icon.width} height={icon.height} />
                                            ) : (
                                                <Icon icon={icon.icon} width={icon.width} height={icon.height} className={`${icon.class}`} />
                                            )}
                                        </div>
                                        <FrontText textSize='16/20' className="text-dark-blue-standard font-bold">
                                            <Trans i18nKey={elem.title} />
                                        </FrontText>
                                    </div>
                                )
                            })}
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default FrontWhatWeOffers;
