import FrontAboutOurCompany from "@/Components/Front/Blocks/FrontAboutOurCompany";
import FrontLatestArticles from "@/Components/Front/Blocks/FrontLatestArticles";
import FrontLatestWarehouses from "@/Components/Front/Blocks/FrontLatestWarehouses";
import FrontServices from "@/Components/Front/Blocks/FrontServices";
import FrontWhatWeOffers from "@/Components/Front/Blocks/FrontWhatWeOffers";
import FrontOurPartners from "@/Components/Front/Blocks/Sliders/FrontOurPartners";
import FrontSearcherWithMap from "@/Components/Front/Forms/FrontSearcherWithMap";
import FrontSeoMetaTags from "@/Components/Front/Partials/FrontSeoMetaTags";
import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import FrontLayout from "@/Layouts/System/FrontLayout";
import { Link } from "@inertiajs/react";

import { Trans, useTranslation } from 'react-i18next';

const Homepage = ({ seo, regions, cities, warehouses, allWarehouses, latestWarehouses, warehousesCount }) => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.resolvedLanguage;
    
    return (
        <FrontLayout newsletterImage={{src: '/storage/images/newsletter-consultant.png', alt: '', name: 'newsletter-consultant'}} newsletterQuote='homepage.newsletter_quote'>
            <FrontSeoMetaTags seo={seo}/>
            <section id="mapa" className="lg:mb-20 md:mb-10">
                <FrontSearcherWithMap warehousesCount={warehousesCount} allWarehouses={allWarehouses} cities={cities} warehouses={warehouses} regions={regions} smallHeading={<Trans i18nKey={'homepage.map_small_heading'} />} heading={<Trans i18nKey={'homepage.map_heading'} />} />
            </section>
            <section id="najnowsze-magazyny">
                <FrontLatestWarehouses
                    cards={latestWarehouses}
                    sectionHeading={<Trans i18nKey={'homepage.latest_warehouses_section_heading'} />}
                />
            </section>

            <section id="uslugi-wiadomosci-kontakt" className="mb-20">
                <FrontServices
                    cards={t('homepage.services_cards', { returnObjects: true })}
                />
            </section>

            <section id="do-wynajecia">
                <FrontWhatWeOffers
                    sectionHeading={
                        <h3>{t('homepage.to_rent_section_heading')}</h3>
                    }
                    content={(
                        <>
                            <p>
                                <Trans i18nKey="homepage.to_rent_desc_1" />
                            </p>
                            <ul>
                                <li>
                                    <Trans i18nKey="homepage.to_rent_desc_2_1" />
                                </li>
                                <li>
                                    <Trans i18nKey="homepage.to_rent_desc_2_2" />
                                </li>
                                <li>
                                    <Trans i18nKey="homepage.to_rent_desc_2_3" />
                                </li>
                            </ul>
                            <p>
                                <Trans i18nKey="homepage.to_rent_desc_3" />
                            </p>
                            <p>
                                <Trans i18nKey="homepage.to_rent_desc_4"/>
                            </p>
                            <p>
                                <Trans i18nKey="homepage.to_rent_desc_5" components={{
                                    0: <Link href={resolveLocaleRoute('front.warehouseIndustrialLease', null, currentLang)}/>,
                                    1: <Link href={resolveLocaleRoute('front.leaseRenegotiations', null, currentLang)}/>,
                                    2: <Link href={resolveLocaleRoute('front.bts', null, currentLang)}/>,
                                    3: <Link href={resolveLocaleRoute('front.propertySale', null, currentLang)}/>,
                                    /* =================== [Brakujące linkowania - dodatek #4 -> Odkomentować komponent linku] =================== */
                                    // 4: <Link href={resolveLocaleRoute('front.landSalesAndPurchases', null, currentLang)}/>,
                                    5: <Link href={resolveLocaleRoute('front.logisticsConsultancy', null, currentLang)}/>
                                }}/>
                            </p>
                            <p>
                                <Trans i18nKey="homepage.to_rent_desc_6" />
                            </p>
                        </>
                    )}
                    image={{
                        name: 'rental_img',
                        src: '/storage/images/rental_img.jpg',
                        alt: t('homepage.to_rent_image_alt'),
                    }}
                    listItems={t('homepage.to_rent_list', { returnObjects: true })}
                    button={{
                        href: resolveLocaleRoute('front.contact', null, i18n.resolvedLanguage),
                        title: t('homepage.contact_with_us'),
                    }}
                />
            </section>

            <section id="analizy-rynkowe-i-raporty" className="xl:mt-c142 lg:mt-20 md:mt-14">
                <FrontLatestArticles
                    heading={<h3>{t('homepage.reports_and_publications_heading')}</h3>}
                    description={
                        <Trans i18nKey={'homepage.reports_and_publications_desc'} />
                    }
                    button={{
                        href: resolveLocaleRoute('front.news.loop', 'raporty', i18n.resolvedLanguage),
                        title: t('homepage.reports_and_publications_show_more'),
                    }}
                />
            </section>

            <section id="nasi-partnerzy" className="xl:mt-c142 lg:mt-25 md:mt-18 mt-8">
                <FrontOurPartners sectionHeading={<h3>{t('homepage.our_partners_section_heading')}</h3>} />
            </section>
            {/* =================== [Dodatek, zmiana odstępów od newslettera w footerze #5 -> zmienić klasę sekcji poniżej na -> 'xl:mt-c142 lg:mt-25 mt-20 xl:mb-10 lg:mb-9 md:mb-8 mb-7'] =================== */}
            <section id="nasza-firma" className="xl:mt-c142 lg:mt-25 mt-20  ">
                <FrontAboutOurCompany
                    sectionHeading={<h3>{t('homepage.our_company_section_heading')}</h3>}
                    image={{
                        name: 'our-company',
                        url: '/storage/images/our-company.jpg',
                        alt: t('homepage.our_company_image_alt'),
                    }}
                    checklist={t('homepage.our_company_checklist', { returnObjects: true })}
                    content={t('homepage.our_company_content')}
                />
            </section>
        </FrontLayout>
    );
};

export default Homepage;
